/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;

  p {
    margin: 0 0 5px 0;
  }

  p.trustmark {
    display: none;
    margin: 0;
  }

  a {
    color: #999999;
  }

  .social a {
    border: 0;
  }

  img {
    display: inline-block;
  }
}

.bg-blue {
  background: #2c69d0 !important;
  &.btn:hover {
    background: darken(#2c69d0, 5%) !important;
  }
}
.blue-top {
  border-top: 4px solid #2c69d0;
}

/* --------- asc styles --------- */
#header {
  float: none;

  a.logo {
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0;
    width: 75%;
    max-width: 650px;
    img {
      display: none;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }

    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }

    span.small {
      display: block !important;
      line-height: 1.2em;
      margin-top: 0;
      font-size: 14px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }

  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;

    p {
      float: right;
      margin: 2px 12px 0 0;

      strong {
        font-size: 16px;
      }
    }

    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }

      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }

    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }

    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }

  #search {
    float: right;
    clear: right;
    margin-top: 13px;
    position: relative;
    input {
      padding-left: 35px;
      padding-right: 10px;
      height: 38px;
      border-radius: 3px;
      transition: all 0.5s;
      min-width: 250px;
      outline: 0;
      border: 1px solid #dcdcdc;
    }
    .search-icon {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 0;
      top: 0;
      text-align: center;
      line-height: 40px;
    }
  }
}

.icon {
  width: 40px;
}

.dmv-offices > li > a:hover {
  background: darken(#f8f9fa, 10%) !important;
}
.dmv-cities > table > tbody > tr > td > a:hover {
  background: darken(#f8f9fa, 10%) !important;
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 230px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 11px;
    padding-bottom: 10px;
    color: #fff;
    font-weight: 400;
    background: $cta-blue !important;
    border: 0;
    border-radius: 0.375rem;
    height: auto;
    line-height: 1;
    font-size: 16px;
    text-align: center;
    //font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    //text-transform: uppercase;
    & + .drop {
      top: 35px;
      width: 230px !important;
      li a {
        font-size: 15px;
        color: #333 !important;
      }
    }
    &.is-active {
      border-radius: 0.375rem 0.375rem 0 0;
    }
    &:after {
      display: none;
    }
    &:hover {
      background: darken($cta-blue, 7) !important;
    }
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo {
    width: 60%;
    img {
      display: block;
      float: left;
      max-width: 120px;
      margin-right: 10px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }

  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    // width: 50%;
    span.small {
      font-size: 16px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
  }
  #header #support {
    display: block;

    span.phone {
      display: inline-block;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header a.logo {
    width: 40%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
